import tangemStore, { Store } from "../lib/store";
import type {
  CheckoutOption,
  PaymentMethod,
  PaymentSystemMethod,
} from "../types";
import { COLLECTION_2_CARDS } from "../data/tangem-wallets";
import {
  activeProduct,
  warehouseStatus,
  isSoldOut,
  isRingPackSelected,
  activeCountry,
  accessory,
  accessoryQuantity,
} from "@components/WalletForm/models/products";
import {
  extraDiscountCode,
  discountCode,
} from "@components/WalletForm/models/discounts";
import { counter } from "@components/WalletForm/models/counter";
import type { CheckoutDetails, CheckoutError } from "../types/dom";
import { amplitude } from "@lib/amplitude/";
import { totalFormatted } from "@components/WalletForm/models/cart";
import { SENTRY_ERROR_MESSAGE_CART_CREATE } from "../utils/constants";

const SHOPLINE_DISABLED = true;

const shop = {
  sweden: {
    href: "https://checkout2.tangem.com",
  },
  shopline: {
    href: "https://checkout1.tangem.com",
  },
};
export class Checkout {
  private _currentSku: string;
  private formElement: HTMLFormElement;
  private totalElement: HTMLElement | undefined;
  private checkoutButtonTextPreorder: HTMLElement;
  private checkoutButtonTextOrder: HTMLElement;
  private checkoutButtonTextSoldOut: HTMLElement;
  private _paymentMethod: PaymentMethod;
  private _tangemStore: Store = tangemStore;
  private readonly _options: CheckoutOption;
  private readonly checkoutButton: HTMLButtonElement;
  private _subscriptions: Array<() => void> = [];
  private taxesText: HTMLElement | null;

  constructor(option: CheckoutOption) {
    this.formElement = document.getElementById(
      "checkout_form",
    ) as HTMLFormElement;
    this.totalElement = document.getElementById("total")!;

    this._options = option;
    this._currentSku = option.defaultSku;
    this._paymentMethod = option.defaultPaymentMethod;

    this.checkoutButton = <HTMLButtonElement>(
      document.getElementById("checkout")!
    );

    this.checkoutButtonTextPreorder = document.getElementById(
      "button-pre-order-text",
    )!;

    this.checkoutButtonTextOrder =
      document.getElementById("button-order-text")!;

    this.checkoutButtonTextSoldOut = document.getElementById(
      "button-sold-out-text",
    )!;

    this.taxesText = document.getElementById("taxes-and-shipping-text");
    this.checkoutButton.addEventListener("click", () => {
      const valid = this.formElement.checkValidity();
      if (valid) {
        this.checkout();
      }
    });

    this.setCheckoutActive(true);

    this._subscriptions.push(
      activeProduct.subscribe((product) => {
        const sku = product?.variants.find((variant) => variant.sku)?.sku;

        if (sku) {
          this.setCurrentSku(sku);
        }

        this.updateCheckoutButton();
      }),

      activeCountry.subscribe(() => {
        this.updateCheckoutButton();
      }),
    );

    setTimeout(() =>
      document.querySelector(".wf-new")?.classList.remove("wf_loadable"),
    );
  }

  private updateCheckoutButton() {
    const { is_available, shipping_starts_after } = warehouseStatus.peek();
    if (isRingPackSelected.peek() && !activeProduct.peek()) {
      // set is ring but no ring size selected
      this.toggleButtonText(true, null);
      this.setCheckoutButtonDisabled(false);
    } else {
      this.toggleButtonText(is_available, shipping_starts_after);
      this.setCheckoutButtonDisabled(
        !is_available && shipping_starts_after === null,
      );
    }
  }

  private setCheckoutButtonDisabled(isSoldOut: boolean) {
    this.checkoutButton.disabled = isSoldOut;
    if (isSoldOut && this.taxesText) {
      this.taxesText.style.display = "none";
    }
  }

  get paymentMethod() {
    return this._paymentMethod;
  }

  private setCurrentSku(sku: string) {
    this._currentSku = sku;
  }

  async initCart(regionId?: string) {
    const variantId = activeProduct.value!.variants[0].id;
    // Create or load cart and clear it
    await this._tangemStore.createCart();
    await this._tangemStore.addToCart(variantId, counter.value);

    const accessoryMedusaId = accessory.peek()?.variants[0]?.id;
    const accessoryItemsQuantity = accessoryQuantity.peek();
    if (accessoryItemsQuantity && accessoryMedusaId) {
      await this._tangemStore.addToCart(
        accessoryMedusaId,
        accessoryItemsQuantity,
      );
    }

    const regularDiscount = discountCode.peek();
    const extraDiscount = extraDiscountCode.peek();

    if (regularDiscount || extraDiscount) {
      const discounts = [regularDiscount, extraDiscount].filter(
        (discountCode) => discountCode !== "",
      );
      try {
        await this._tangemStore.applyDiscounts(discounts);
      } catch (e) {
        this.createErrorEvent(SENTRY_ERROR_MESSAGE_CART_CREATE);
      }
    }
  }

  private toggleButtonText(
    isAvailable: boolean,
    shippingStartsAfter: string | null,
  ) {
    const hiddenClassName = "checkout__button-text_hidden";
    const totalHiddenClassName = "checkout__total_hidden";
    const isSoldOut = !isAvailable && shippingStartsAfter === null;

    const setClassNames = (
      orderHidden: boolean,
      preorderHidden: boolean,
      soldOutHidden: boolean,
    ) => {
      this.checkoutButtonTextOrder.classList.toggle(
        hiddenClassName,
        orderHidden,
      );
      this.checkoutButtonTextPreorder.classList.toggle(
        hiddenClassName,
        preorderHidden,
      );
      this.checkoutButtonTextSoldOut.classList.toggle(
        hiddenClassName,
        soldOutHidden,
      );
      this.totalElement?.classList.toggle(totalHiddenClassName, !soldOutHidden);
    };

    if (isAvailable) {
      setClassNames(false, true, true);
    } else if (isSoldOut) {
      setClassNames(true, true, false);
    } else {
      setClassNames(true, false, true);
    }
  }

  private setCheckoutActive(value: boolean) {
    const disabled = !value || isSoldOut.value;
    this.checkoutButton.classList.toggle("button__loading", !value);
    this.checkoutButton?.toggleAttribute("disabled", disabled);
  }

  private checkout() {
    const paymentButton = <HTMLButtonElement>(
      document.querySelector('input[name="paymentMethod"]:checked')
    );

    if (paymentButton) {
      this._paymentMethod = <PaymentMethod>paymentButton.value;
    }

    const cardsInSet =
      activeProduct.value?.collection.title === COLLECTION_2_CARDS ? 2 : 3;

    const isABTest =
      document.querySelector("#payment-card")?.classList.contains("ab") ||
      SHOPLINE_DISABLED;
    const cardPaymentSystem = isABTest ? "medusa" : "shopline";

    document.dispatchEvent(
      new CustomEvent<CheckoutDetails>("cart-create", {
        detail: {
          quantity: counter.value,
          discountCode: discountCode.peek(),
          sku: this._currentSku,
          paymentSystem:
            this._paymentMethod === "card" ? cardPaymentSystem : "medusa",
          cardsInSet,
          cardName: activeProduct.value?.subtitle ?? "",
        },
      }),
    );

    const paymentMethodName: {
      [key in PaymentMethod | PaymentSystemMethod]: string;
    } = {
      card: isABTest ? "card:medusa" : "card:shopline",
      paypal: "paypal:medusa",
      insales: "card:insales",
      medusa: "medusa",
    };
    try {
      this.setCheckoutActive(false);
      const subtitleKebabCase =
        (activeProduct.value?.subtitle || "")
          .replaceAll(" ", "_")
          .toLowerCase() ?? "unknown";

      const cardsInSet =
        activeProduct.value?.collection.title === COLLECTION_2_CARDS ? 2 : 3;

      const checkoutEvent = new CustomEvent("checkout", {
        detail: {
          quantity: counter.value,
          discountCode: discountCode.peek(),
          total: totalFormatted.value,
          currency: this._options.formatOption.currency,
          paymentMethod: paymentMethodName[this._paymentMethod],
          sku: this._currentSku,
          setType: `${cardsInSet}_cards_set_${subtitleKebabCase}`,
          paymentSystem: this._paymentMethod,
          cardsInSet,
          cardName: activeProduct.value?.subtitle,
        },
      });
      window.dispatchEvent(checkoutEvent);
    } finally {
      const checkoutMethods: {
        [key in PaymentMethod | PaymentSystemMethod]: () => void;
      } = {
        card: () =>
          isABTest
            ? this.checkoutByMedusa({ ab: 1 })
            : this.checkoutByShopline(),
        paypal: () => this.checkoutByMedusa(),
        insales: () => {},
        medusa: () => this.checkoutByMedusa(),
      };
      this._subscriptions.forEach((unsubscribe) => unsubscribe());
      checkoutMethods[this._paymentMethod]();
    }
  }

  private checkoutByShopline() {
    const searchParams = new URLSearchParams();
    searchParams.append(
      "attributes[device_id]",
      String(amplitude.getDeviceId()),
    );
    searchParams.append(
      "attributes[session_id]",
      String(amplitude.getSessionId()),
    );
    if (discountCode.peek()) {
      searchParams.set("discount", discountCode.peek());
    }

    const shoplineId = activeProduct.peek()?.metadata?.shopline;
    const accessoryShoplineId = accessory.peek()?.metadata?.shopline;
    const accessoryItemsQuantity = accessoryQuantity.peek();
    const isWithAccessory = accessoryShoplineId && accessoryItemsQuantity;

    const link = isWithAccessory
      ? `${shop.shopline.href}/cart/${shoplineId}:${
          counter.value
        },${accessoryShoplineId}:${accessoryItemsQuantity}?${searchParams.toString()}`
      : `${shop.shopline.href}/cart/${shoplineId}:${
          counter.value
        }?${searchParams.toString()}`;

    window.open(link, "_self");
  }

  private async checkoutByMedusa({ ab }: { ab: number } = { ab: 0 }) {
    const checkoutParams: string[][] = [];
    if (ab > 0) {
      checkoutParams.push([`ab_${ab}`, "true"]);
    }
    try {
      await Promise.all([
        this.goToStore(checkoutParams),
        new Promise<void>((resolve) => {
          setTimeout(() => {
            this.createErrorEvent(SENTRY_ERROR_MESSAGE_CART_CREATE);
            resolve();
          }, 15000);
        }),
      ]);
    } catch (e) {
      const medusaFailedEvent = new CustomEvent<CheckoutError>("medusa-fail", {
        detail: {
          error: (e as Error).toString() ?? "none",
        },
      });
      document.dispatchEvent(medusaFailedEvent);
      this.createErrorEvent(SENTRY_ERROR_MESSAGE_CART_CREATE);
    }
  }

  private async goToStore(checkoutParams: string[][] = []) {
    await this.initCart();
    tangemStore.checkout(checkoutParams);
  }

  private createErrorEvent(err: string) {
    window.dispatchEvent(
      new CustomEvent("sentry", {
        detail: {
          err
        },
      }),
    );
  }
}
